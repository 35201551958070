/* width */
::-webkit-scrollbar {
  width: 2.5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray !important;
}

.modal-summary-table td:last-of-type {
  text-align: center;
}
