// Modal Styles starts here
.modalholder {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.modalheading {
  font-family: 'Proxima-Nova-Semibold';
  font-size: 20px;
  width: 100%;
}

.modaltext {
  font-family: 'Proxima-Nova';
  font-size: 16px;
  width: 100%;
}

.sliderholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.modalsecondhead {
  width: 66%;
  font-size: 16px;
  font-family: 'Proxima-Nova-Semibold';
  margin-bottom: 10px;
}

.modalbutton {
  margin-top: 20px;
  width: 200px;
}

// Modal Styles ends here

// Main file Styles starts here

.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: 65vh;
  margin-top: 20px;
}

.firstcontainer {
  flex: 0.2;
  padding: 20px;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 6px;
}

.headingholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-family: 'Proxima-Nova-Semibold';
  font-size: 20px;
  color: #373737;
}

.imagecount {
  font-family: 'Proxima-Nova-Light';
  font-size: 20px;
}

.imageContainer {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.secondheading {
  font-family: 'Proxima-Nova';
  font-size: 16px;
  color: #373737;
}

.framesholder {
  margin-top: 12px;
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;
  grid-auto-rows: min-content;

  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }
}

.secondcontainer {
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 6px;
  height: 100%;
  background: white;
}

.frameimage {
  width: 100%;
  height: auto;
}

.annotationoptionsholder {
  padding: 10px 20px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  height: 5vh;
  z-index: 0;
}

.iconsholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  z-index: 0;
}

.divider {
  height: 30px;
  width: 1px;
  background: #707070;
}

.thirdcontainer {
  flex: 0.2;
  padding: 20px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.objectlablesholder {
  flex: 2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.placeholder {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.imagetext {
  font-size: 16px;
  color: #8d8d8d;
  font-family: 'Proxima-Nova-Light';
}

.grouplabelicon {
  width: 60%;
}

.actioniconactive {
  background: black;
  padding: 2px;
  box-shadow: 2px 1px 5px 1px grey;
  border-radius: 4px;
}

.actionicon:hover {
  cursor: pointer;
}

.actionicon {
  &.disabled {
    z-index: 0;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.annotationListHolder {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
}

.annotationList {
  width: 100%;
  display: flex;
  padding: 4px;
  border-radius: 4px;
}
