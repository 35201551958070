.select {
  display: inline-flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background-color: white;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: black;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.2), 0 1px 2px -1px rgb(0 0 0 / 0.2);
  color-scheme: light;
  width: fit-content;
  min-width: 100px;
}

.select:hover {
  background-color: #ede9fe;
}

.select:focus {
  outline: none;
}

@media (min-width: 640px) {
  .select {
    height: 2.25rem;
    font-size: 15px;
  }
}

.selectIcon {
  translate: 4px 0;
}

.popover {
  z-index: 50;
  max-height: min(var(--radix-select-content-available-height), 336px);
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.25),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  color-scheme: light;
  width: 100%;
}

.popover {
  scrollbar-width: 10px; /* Firefox */
  -ms-overflow-style: auto; /* IE and Edge */
}

.popover::-webkit-scrollbar {
  display: block; /* Chrome, Safari, and Opera */
  width: 10px; /* Custom width */
}

.popover::-webkit-scrollbar-thumb {
  background-color: darkgray; /* Custom scrollbar color */
  border-radius: 10px; /* Optional: rounded corners */
}

.comboboxWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  padding-bottom: 0px;
}

.combobox {
  height: 2.5rem;
  appearance: none;
  border-radius: 0.25rem;
  background-color: rgb(0 0 0 / 0.05);
  padding-right: 0.5rem;
  padding-left: 1.75rem;
  border: none;
  color: black;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}

.combobox::placeholder {
  color: rgb(0 0 0 / 0.6);
}

@media (min-width: 640px) {
  .combobox {
    height: 2.25rem;
    font-size: 0.9vw;
  }
}

.comboboxIcon {
  pointer-events: none;
  position: absolute;
  top: 0.8rem;
  left: 0.625rem;
  color: rgb(0 0 0 / 0.6);
}

.listbox {
  overflow-y: auto;
  padding: 0.25rem;
  font-size: 0.9vw;
}

.item {
  position: relative;
  display: flex;
  height: 2.5rem;
  cursor: default;
  scroll-margin-top: 0.25rem;
  scroll-margin-bottom: 0.25rem;
  font-size: 0.9vw;
  align-items: center;
  border-radius: 0.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  color: black;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.item[data-active-item] {
  background-color: #ddd6fe;
}

@media (min-width: 640px) {
  .item {
    height: 2.25rem;
    font-size: 0.9vw;
  }
}

.itemIndicator {
  position: absolute;
  left: 0.375rem;
}
