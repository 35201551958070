@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Proxima-Nova';
  src: url('./assets/fonts/Proxima-Nova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima-Nova-Bold';
  src: url('./assets/fonts/Proxima-Nova-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima-Nova-Extra-Bold';
  src: url('./assets/fonts/Proxima-Nova-Extra-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima-Nova-Extra-Light';
  src: url('./assets/fonts/Proxima-Nova-Extra-Light.woff') format('woff');
}

@font-face {
  font-family: 'Proxima-Nova-Light';
  src: url('./assets/fonts/Proxima-Nova-Light.woff') format('woff');
}

@font-face {
  font-family: 'Proxima-Nova-Medium';
  src: url('./assets/fonts/Proxima-Nova-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Proxima-Nova-Semibold';
  src: url('./assets/fonts/Proxima-Nova-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima-Nova-Thin';
  src: url('./assets/fonts/Proxima-Nova-Thin.woff') format('woff');
}

html,
body {
  margin: 0;
  font-family: 'Proxima-Nova';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Proxima-Nova';
}

.css-bmwurw-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-radius: 0;
}

/* ::-webkit-scrollbar {
  display: none;
} */
