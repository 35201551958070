.main {
  width: 100%;
  height: 60vh;
}

.canvasContainer {
  width: 100%;
  height: 50%;
  background-color: rgb(249, 250, 251);
}

.nodeContainer {
  background: white;
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 16px 20px;
  position: relative;
  margin: 10px 0;
}

.nodeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.nodeLabel {
  font-weight: 500;
  font-size: 16px;
  color: rgb(31, 41, 55);
}

.nodeActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.nodeEditButton,
.nodeDeleteButton {
  padding: 4px;
  border-radius: 4px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(243, 244, 246);
  }
}

.nodeDeleteButton {
  color: #ef4444; // Red color for delete button

  &:hover {
    background-color: rgb(254, 226, 226); // Light red background on hover
  }
}

.nodeHandle {
  background: #4299e1 !important;
  border: 2px solid white !important;
  width: 8px !important;
  height: 8px !important;
  min-width: 8px !important;
  min-height: 8px !important;
  transform: none !important;

  &:before {
    display: none !important;
  }
}

.nodeContainer {
  background: white;
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px 20px;
  position: relative;
  margin: 8px 0;
  z-index: 1;
}

.addChildContainer {
  display: flex;
  justify-content: center;
  margin: 8px 0;
}

.addChildButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #f3f4f6;
  border: none;
  color: #4299e1;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  &:hover:not(.disabled) {
    background-color: #e5e7eb;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #e5e7eb;
    color: #9ca3af;

    svg {
      color: #9ca3af;
    }
  }

  svg {
    color: #4299e1;
  }
}

.labelSelector {
  position: fixed;
  right: 3%;
  top: 18%;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 256px;

  h3 {
    font-weight: 500;
    margin-bottom: 8px;
  }

  .labelList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 40vh;
    overflow-y: auto;
  }

  .labelButton {
    text-align: left;
    padding: 8px 16px;
    border-radius: 6px;
    color: #2563eb;
    transition: background-color 0.2s;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #eff6ff;
    }
  }
}

.editLabelDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 256px;

  h3 {
    font-weight: 500;
    margin-bottom: 8px;
  }

  .labelList {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .labelButton {
    text-align: left;
    padding: 8px 16px;
    border-radius: 6px;
    color: #2563eb;
    transition: background-color 0.2s;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #eff6ff;
    }
  }

  .cancelButton {
    width: 100%;
    margin-top: 16px;
    padding: 8px 16px;
    border-radius: 6px;
    background-color: rgb(243, 244, 246);
    transition: background-color 0.2s;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgb(229, 231, 235);
    }
  }
}

.canvasContainer {
  width: 100%;
  height: 80vh;
  background-color: rgb(249, 250, 251);
  position: relative;

  :global {
    .react-flow__edge-path {
      stroke: #4299e1;
      stroke-width: 2;
    }

    .react-flow__edge {
      z-index: 1;
    }

    .react-flow__handle {
      opacity: 1 !important;
    }
  }
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: inherit;
    }
  }
}
