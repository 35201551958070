/* width */
::-webkit-scrollbar {
  /* width: 1px !important; */
  height: 3px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.swal2-title,
.swal2-content {
  text-align: center;
  font-size: 26px; /* Left-align the title and content text */
}

.swal2-html-container {
  text-align: center !important;
}

.swal2-actions {
  width: 100%;
  justify-content: center;
  gap: 10px;
  padding: 0 1rem;
}

.swal2-title2,
.swal2-content2 {
  text-align: center;
}

.swal2-html-cont2 {
  text-align: center !important;
}

.swal2-html {
  text-align: center !important;
}
