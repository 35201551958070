.initialFlow {
  width: 100%;
  align-items: center;
  background-color: red;
}

.cameraCard {
  width: 50vw;
}

.notify-container {
  background-color: rgb(0, 0, 0, 0.5);
  color: white;
  width: '100%';
  border-radius: 50px;
  padding: 2px 4px;
  display: flex;
  transition: width 0.3s, height 0.3s, box-shadow 0.3s ease;
  align-items: center;
  word-wrap: break-word;
}

.annotation-container {
  display: flex;
}

.notify-container:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  width: '100%';
  cursor: pointer;
}

.notify-main-block {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  margin-top: 10px;
}

.notify-main-block > :first-child {
  margin-top: auto !important;
}

.notification-head {
  display: flex;
  font-size: 15px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: normal !important;
  white-space: wrap;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.timer {
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.notify-main-data {
  margin-left: 10px;
}

.notification-bar {
  position: absolute;
  height: 200px;
  width: 340px;
  top: 60px;
  right: 20px;
  z-index: 10;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: white !important;
    opacity: 0.1 !important; */
  background: gray !important;
  /* opacity: 0.5; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d4cdcd !important;
}

.annotation-alert-container {
  background-color: white;
  height: 29.3vw;
  overflow-y: auto;
  box-shadow: 4px 1px 10px 2px grey;
}

.alert-table-container {
  height: 100%;
  overflow-y: auto;
}

.alerts-title {
  padding-top: 10px;
  padding-bottom: 30px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
}

.annotation-data-table {
  width: 100%;
  margin-top: 15px;
}

.notify-container .MuiCircularProgress-root {
  color: #d4cdcd;
}

.notify-container:hover .MuiCircularProgress-root {
  color: #000;
}
