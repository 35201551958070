.dropdownLabel {
  font-size: 16px;
  text-align: center;
  margin: 0;
  height: 24px;
  display: flex;
  align-items: center;
  color: #808080;
  justify-content: center;
}

.filtericon {
  width: 30px;
}

.dropdownItem {
  font-size: 14px;
  text-align: center;
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownItem:hover {
  cursor: pointer;
  background: #102633;
  color: white;
}

.separatorColor {
  margin: 0;
  box-shadow: '2px 1px 5px 1px grey';
}

.noModelsText {
  font-size: 14px;
  text-align: center;
  height: 30px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
