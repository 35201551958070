.timeline-editor-engine .player-panel {
  width: 100%;
  max-width: 100% !important;
  height: 300px;
  position: relative;
}

.timeline-editor-engine .player-panel .lottie-ani {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.timeline-editor-engine .timeline-editor {
  width: 100%;
  /* max-width: 100% !important;
  background: #fff; */
  max-width: none;
  /* Remove the max-width constraint */
  background: #fff;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent the content from wrapping */
}

.timeline-editor-engine .timeline-editor-action {
  height: 28px !important;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-editor-engine .timeline-editor-action {
  border-radius: 4px;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 {
  background-color: #cd9541;
  /* background-image: url('/assets/soundWave.png'); */
}

.timeline-editor-engine .timeline-editor-action-effect-effect1 {
  background-color: #7846a7;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0,
.timeline-editor-engine .timeline-editor-action-effect-effect1 {
  cursor: pointer;
  background-position: bottom;
  background-repeat: repeat-x;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect0,
.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect1,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect0,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect1 {
  width: 100%;
  height: 100%;
  font-size: 10px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect0-text,
.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect1-text,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect0-text,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect1-text {
  margin-left: 4px;
  flex: 1 1 auto;
  text-align: center;
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect0
  .timeline-editor-action-left-stretch::after,
.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .timeline-editor-action-left-stretch::after {
  border-left: 7px solid rgba(255, 255, 255, 0.4);
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect0
  .timeline-editor-action-right-stretch::after,
.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .timeline-editor-action-right-stretch::after {
  border-right: 7px solid rgba(255, 255, 255, 0.4);
}

.timeline-editor-engine .timeline-player {
  height: 32px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3a3a3a;
  color: #ddd;
}

.timeline-editor-engine .timeline-player .play-control {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  background-color: #666;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.timeline-editor-engine .timeline-player .time {
  font-size: 12px;
  margin: 0 20px;
  width: 70px;
}

.timeline-editor-engine .timeline-player .rate-control {
  justify-self: flex-end;
}

.timeline-editor-engine .timeline-player .rate-control .ant-select {
  width: 90px !important;
}

.timeline-editor-engine
  .timeline-player
  .rate-control
  .ant-select
  .ant-select-selector {
  border: none;
  box-shadow: none !important;
  background-color: transparent;
  color: #ddd;
}

.timeline-editor-engine
  .timeline-player
  .rate-control
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.timeline-editor-engine .timeline-player .rate-control .ant-select-arrow {
  color: #ddd;
}

.timeline-editor-example7 {
  display: flex;
  background-color: #191b1d;
  max-height: 280px;
  width: 100%;
}

.timeline-list::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari */
}

.timeline-editor-example7 .timeline-list {
  width: 220px;
  margin-top: 42px;
  flex: 0 1 auto;
  overflow: overlay;
  padding: 0 10px;
  border-right: 1px solid #a3acb1;
}

.timeline-editor-example7 .timeline-list-item {
  height: 40px;
  padding: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-editor-example7 .timeline-list-item .text {
  color: #fff;
  height: 28px;
  width: 100%;
  padding-left: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #333;
  border: none;
}

.timeline-editor-example7 .timeline-editor {
  height: 100%;
  flex: 1 1 auto;
  background: #fff;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.timeline-editor-example7 .timeline-editor-action {
  height: 28px !important;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-editor,
.timeline-editor .timeline-editor-edit-row,
.timeline-list,
.timeline-editor-example7,
.timeline-editor-example7 .timeline-list-item .text,
.timeline-editor-engine .timeline-player {
  background: #fff;
}

.timeline-editor-engine .timeline-player .play-control {
  background: #fff;
}

.timeline-editor-engine .timeline-player .time {
  color: #000;
}

.play-control svg {
  fill: #102633;
}

.timeline-editor .timeline-editor-time-unit-scale,
.timeline-editor-example7 .timeline-list-item .text {
  color: #000;
}

.timeline-editor-time-unit-big:first-of-type .timeline-editor-time-unit-scale {
  padding-left: 25px;
}

.timeline-editor-time-unit-big:last-of-type .timeline-editor-time-unit-scale {
  padding-right: var(--dynamic-padding, 30px);
}

.timeline-editor-example7 .timeline-list-item .text {
  background: #ededed;
}

.timeline-list {
  border-radius: 1px solid #a3acb1;
  padding-right: 5px;
  margin-right: 5px;
}

.timeline-player {
  border-bottom: 1px solid;
}

.timeline-editor .timeline-editor-time-unit {
  border-right: 1px solid #000;
}

.timeline-editor .timeline-editor-time-unit:last-child {
  border-right: none;
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect0
  .timeline-editor-action-left-stretch::after,
.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .timeline-editor-action-left-stretch::after {
  border-left: 7px solid rgb(61 134 245);
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect0
  .timeline-editor-action-right-stretch::after,
.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .timeline-editor-action-right-stretch::after {
  border-right: 7px solid rgb(61 134 245);
}

.timeline-list-item .timeline-list-item-action {
  display: flex;
  background: #ededed;
  padding: 0px 3px;
  border-radius: 3px;
}

.timeline-editor
  .timeline-editor-edit-row
  .timeline-editor-action
  .effect0
  .effect0-action-icon {
  position: absolute;
  right: -9px;
  top: -9px;
  /* background: #fff; */
  border-radius: 51px;
  padding: 0;
  z-index: 1;
}

.timeline-editor
  .timeline-editor-edit-row
  .timeline-editor-action
  .effect0
  .effect0-action-icon
  svg {
  fill: #ff0000;
  height: 15px;
  width: 15px;
  background: #fff;
  border-radius: 15px;
}

.timeline-editor-action .timeline-editor-action-left-stretch,
.timeline-editor-action .timeline-editor-action-right-stretch {
  position: absolute;
  top: 7px !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px !important;
  overflow: hidden;
}

.timeline-editor-action .timeline-editor-action-right-stretch {
  right: -8px !important;
}

.timeline-editor-action .timeline-editor-action-left-stretch {
  left: -8px !important;
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect0
  .timeline-editor-action-right-stretch::after,
.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .timeline-editor-action-right-stretch::after {
  border-right: 8px solid rgb(255 0 0);
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect0
  .timeline-editor-action-left-stretch::after,
.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .timeline-editor-action-left-stretch::after {
  border-left: 8px solid rgb(255 0 0);
}

/* .timeline-editor-engine
  .timeline-editor-action-effect-effect0
  .timeline-editor-action-left-stretch::after,
.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .timeline-editor-action-left-stretch::after {
} */
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect0 {
  border: 2px solid red;
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .effect0.selected {
  background-color: #00800040;
  border: 2px solid green;
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .effect0.selected
  + .timeline-editor-action-left-stretch::after {
  border-left: 8px solid green;
}

.timeline-editor-engine
  .timeline-editor-action-effect-effect1
  .effect0.selected
  + .timeline-editor-action-left-stretch
  + .timeline-editor-action-right-stretch::after {
  border-right: 8px solid green;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.table-header-workflow > div:nth-of-type(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #102633;
}

/* zoom the cursor when hover.
    This is needed to distinguish the cursor from the clip cursor */
.timeline-editor-cursor:hover .timeline-editor-cursor-top {
  zoom: 150%;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 #102633;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #102633;
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }

  50% {
    transform: rotate(360deg) scale(1.2);
  }

  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #102633;
  }

  50% {
    box-shadow: 0 0 0 #102633;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #102633;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }

  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}
